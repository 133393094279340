<template>
  <div class="layout">
    <div class="store_bg"></div>
    <van-loading v-if="isLoading" />
    <div class="list" v-else>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
        @load="getInfo"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <img
            v-lazy="item.platformPic"
            alt=""
            @click="toActivityDetail(item)"
          />

          <div class="goods_box">
            <div
              class="goods"
              v-for="g in item.goodsList"
              :key="g.id"
              @click="toGoods(g)"
            >
              <div class="img_box">
                <img v-lazy="g.filePath" alt="" />
              </div>

              <div class="price">
                <span>￥</span><strong>{{ price(g) }}</strong>
              </div>
            </div>
            <p class="more" @click="toActivityDetail(item)">
              更多<svg-icon icon-class="arrow_right" />
            </p>
          </div>
        </div>
      </van-list>
    </div>
    <functionButton />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { preferential } from "@/utils/validate";
export default {
  name: "activity",
  data() {
    return {
      list: [],
      isLoading: true,
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pages: 0,
    };
  },
  computed: {
    ...mapState("user", ["userType", "tzInfo", "userInfo", "isLogin"]),
    price() {
      return function (val) {
        if (val.goodsStatus === 1) {
          return val.storeTastingActivityInfoEntity.price.toFixed(2);
        } else if (val.goodsStatus === 2) {
          return val.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(2);
        } else if (this.userType === "PURCHASE") {
          return preferential(val, "tradePrice").price;
        } else {
          return preferential(val, "retailPrice").price;
        }
      };
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      await this.$API.homePage
        .getActivityList({
          object: {
            freeShipping: this.tzInfo
              ? this.tzInfo.userEntity.freeShipping
              : "",
            location: "1",
            userId: this.isLogin ? this.userInfo.id : undefined,
          },
          pageNum: this.pageNum,
          pageSize: 4,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          res.data.data.forEach((list) => {
            list.platformPic = list.platformPic.split(",")[1];
            if (list.commodityDetailsList) {
              list.goodsList = [];
              let goods = list.commodityDetailsList.map((goods, index) => {
                return {
                  ...goods,
                  ...list.commodityPriceDetailsList[index],
                  ...list.commodityActivityRspList[index],
                };
              });

              goods.forEach((item) => {
                item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
                  ? item.filePath.split(",")[1]
                  : item.filePath.split(",")[0];
                if (
                  list.newUser &&
                  item.storeTastingActivityInfoEntity &&
                  item.querySecKillCommodityListRsp
                ) {
                  if (
                    item.storeTastingActivityInfoEntity.price <=
                    item.querySecKillCommodityListRsp.retailSeckillPrice
                  ) {
                    item.goodsStatus = 1;
                  } else {
                    item.goodsStatus = 2;
                  }
                } else if (
                  list.newUser &&
                  item.storeTastingActivityInfoEntity
                ) {
                  item.goodsStatus = 1;
                } else if (item.querySecKillCommodityListRsp) {
                  item.goodsStatus = 2;
                } else {
                  item.goodsStatus = 0;
                }
                // console.log(list.goodsList);
                list.goodsList.push(item);
              });

              // Goods = Goods.filter((item) => item.storeId !== null);
              // Goods.forEach((item) => {
              //   item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
              //     ? item.filePath.split(",")[1]
              //     : item.filePath.split(",")[0];
              //   if (
              //     list.newUser &&
              //     item.storeTastingActivityInfoEntity &&
              //     item.querySecKillCommodityListRsp
              //   ) {
              //     if (
              //       item.storeTastingActivityInfoEntity.price <=
              //       item.querySecKillCommodityListRsp.retailSeckillPrice
              //     ) {
              //       item.goodsStatus = 1;
              //     } else {
              //       item.goodsStatus = 2;
              //     }
              //   } else if (
              //     list.newUser &&
              //     item.storeTastingActivityInfoEntity
              //   ) {
              //     item.goodsStatus = 1;
              //   } else if (item.querySecKillCommodityListRsp) {
              //     item.goodsStatus = 2;
              //   } else {
              //     item.goodsStatus = 0;
              //   }
              // });
              // list.commodityDetailsList = Goods;
              // let g = [];
              // if (
              //   list.commodityDetailsList.length &&
              //   list.commodityDetailsList.length < 4
              // ) {
              //   list.goodsList.push(list.commodityDetailsList);
              // } else {
              //   list.commodityDetailsList.forEach((commodityDetailsList, i) => {
              //     g.push(commodityDetailsList);
              //     if ((i + 1) % 4 == 0) {
              //       list.goodsList.push(g);
              //       g = [];
              //     }
              //     if (list.commodityDetailsList.length - 1 === i && g.length)
              //       list.goodsList.push(g);
              //   });
              // }
            }
            this.list.push(list);
          });
          // this.list.map((list) => {
          //   if (list.commodityDetailsList) {
          //     list.goodsList = [];
          //     let Goods = list.commodityDetailsList.map((goods, index) => {
          //       return {
          //         ...goods,
          //         ...list.commodityPriceDetailsList[index],
          //         ...list.commodityActivityRspList[index],
          //       };
          //     });

          //     Goods = Goods.filter((item) => item.storeId !== null);
          //     Goods.forEach((item) => {
          //       item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
          //         ? item.filePath.split(",")[1]
          //         : item.filePath.split(",")[0];
          //       if (
          //         list.newUser &&
          //         item.storeTastingActivityInfoEntity &&
          //         item.querySecKillCommodityListRsp
          //       ) {
          //         if (
          //           item.storeTastingActivityInfoEntity.price <=
          //           item.querySecKillCommodityListRsp.retailSeckillPrice
          //         ) {
          //           item.goodsStatus = 1;
          //         } else {
          //           item.goodsStatus = 2;
          //         }
          //       } else if (
          //         list.newUser &&
          //         item.storeTastingActivityInfoEntity
          //       ) {
          //         item.goodsStatus = 1;
          //       } else if (item.querySecKillCommodityListRsp) {
          //         item.goodsStatus = 2;
          //       } else {
          //         item.goodsStatus = 0;
          //       }
          //     });
          //     list.commodityDetailsList = Goods;
          //     let g = [];
          //     if (
          //       list.commodityDetailsList.length &&
          //       list.commodityDetailsList.length < 4
          //     ) {
          //       list.goodsList.push(list.commodityDetailsList);
          //     } else {
          //       list.commodityDetailsList.forEach((commodityDetailsList, i) => {
          //         g.push(commodityDetailsList);
          //         if ((i + 1) % 4 == 0) {
          //           list.goodsList.push(g);
          //           g = [];
          //         }
          //         if (list.commodityDetailsList.length - 1 === i && g.length)
          //           list.goodsList.push(g);
          //       });
          //     }
          //   }
          //   // console.log(item.commodityDetailsList);
          // });
          this.pages = res.data.pages;
          if (this.pageNum >= this.pages) {
            this.finished = true;
          } else {
            this.pageNum++;
          }
          this.loading = false;
          this.isLoading = false;
        })
        .catch((err) => {
          this.Toast.fail(err.message);
          console.error(err.message);
          this.error = true;
          this.loading = false;
          this.isLoading = false;
        });
    },
    toActivityDetail(data) {
      this.$router.push({
        path: "activity/activityDetail",
        query: {
          activityId: data.id,
        },
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    toGoods(val) {
      this.$router.push({
        path: "goodsDetails",
        query: {
          barcode: val.barcode,
          enterpriseId: val.enterpriseId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  background: #f5f5f5;
  height: 100%;
  background: #f91c12;

  .van-loading {
    flex: 1;
    background: #f91c12;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
  }
}
.store_bg {
  background: url("../../assets/images/hd_bg.png");
  width: 100%;
  height: 80px;
  font-size: 0;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  flex-shrink: 0;
  .back {
    position: relative;
  }
  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 16px;
  }
  p {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }
}
.list {
  padding: 0 15px;
  background: #f91c12;
  .item {
    position: relative;
    text-align: left;
    margin-bottom: 10px;
    > img {
      width: 100%;
      border-radius: 10px 10px 0 0;
      display: block;
      // height: 115px;
      // object-fit: contain
    }
  }
  .goods_box {
    display: flex;
    background: #fff;
    position: relative;
    padding-right: 32px;
    border-radius: 0px 0px 10px 10px;
    .goods {
      padding: 12px 0;
      padding-left: 12px;
      width: calc(100% / 3);
      text-align: center;
      .img_box {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 100%;
        border-radius: 4px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          // height: 94px;
          object-fit: contain;
        }
      }

      .price {
        color: #f91c12;
        font-size: 18px;
        margin-top: 8px;
        // text-align: center;
        // width: 85px;
        span {
          font-size: 10px;
          display: inline-block;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scaleY(0.8);
          position: relative;
          left: 2px;
          top: 1px;
        }
      }
    }
    .more {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 32px;
      font-size: 14px;
      color: #666666;
      padding: 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // line-height: 14px;
      .svg-icon {
        font-size: 10px;
        transform: rotate(90deg);
        flex-shrink: 0;
      }
    }
  }
  ::v-deep .van-list__error-text {
    color: #fff;
  }
  ::v-deep .van-list__finished-text {
    color: #fff;
  }
  ::v-deep .van-loading__text {
    color: #fff;
  }
}
</style>
